// Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HasId } from 'src/app/core/entity/entity.service';
import { Sector } from 'src/app/industry/industry.model';

import { CompanyRecord } from '../company-record.model';
import { _enforceSameEnumKeys } from 'src/app/shared/util.tooling';
import { ETransactionType } from 'src/app/dataset/dataset.model';

export interface CompanyRecordSector extends HasId {
  record: CompanyRecord;
  sector: Sector;
  weight: number;
  scope: keyof typeof SectorScope;
  source: keyof typeof SectorSource;
  status: keyof typeof SectorStatus;
}

export enum SectorScope {
  EXPENDITURE = 'Expenditure',
  INVESTMENT = 'Investment',
  REVENUE = 'Revenue',
}
_enforceSameEnumKeys(SectorScope, ETransactionType);

export enum SectorSource {
  AUTOMATED = 'Automated',
  MANUAL = 'Manual',
}

export enum SectorStatus {
  VERIFIED = 'Verified',
  PENDING_REVIEW = 'Pending Review',
}
