<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<section *ngLet="sectors$ | async as editingSectors">
  <div *ngIf="dtOptions; else loading" class="table-responsive">
    <table datatable [dtOptions]="dtOptions" class="table table-borderless table-hover">
      <thead>
        <tr>
          <th>Scope</th>
          <th>Country</th>
          <th>Industry</th>
          <th class="text-right">Weight</th>
          <th>Source</th>
          <th>Status</th>
          <th *ngIf="editable" data-sortable="false">Actions</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <th>Total</th>
          <td></td>
          <td></td>
          <td class="align-middle text-right weight-highlight" data-subtotal data-precision></td>
          <td></td>
          <td></td>
          <td *ngIf="editable"></td>
        </tr>
      </tfoot>
    </table>
  </div>

  <div class="mt-4 mb-4 d-flex gap-4 justify-content-between">
    <button class="btn btn-outline btn-sm" (click)="import([blankSector()], editingSectors, false)">Add sector</button>

    <button class="btn btn-secondary btn-sm" (click)="calculateWeights(editingSectors)">Calculate Weights</button>
  </div>

  <ng-template #countryCell let-data="adtData">
    <ng-container *ngIf="!data.editing; else editCompanyRecordSectorCountryTemplate">
      <div class="d-flex align-items-center f32">
        <span class="flag {{ data.sector.country?.short.toLowerCase() }}"></span>
        {{ data.sector.country?.name ?? (!data.id ? 'Select Country...' : 'Requires Country') }}
      </div>
    </ng-container>
    <ng-template #editCompanyRecordSectorCountryTemplate>
      <select class="form-control" [(ngModel)]="data.sector.country" [compareWith]="compareIds" required>
        <option value="" disabled selected>Select your country...</option>
        <option *ngFor="let c of countries$ | async" [ngValue]="c">{{ c.name }}</option>
      </select>
    </ng-template>
  </ng-template>

  <ng-template #industryCell let-data="adtData">
    <ng-container *ngIf="!data.editing; else editCompanyRecordSectorIndustryTemplate">
      {{ data.sector.industry?.name ?? (!data.id ? 'Select Industry...' : 'Requires Industry') }}
    </ng-container>
    <ng-template #editCompanyRecordSectorIndustryTemplate>
      <select class="form-control" [(ngModel)]="data.sector.industry" [compareWith]="compareIds" required>
        <option value="" disabled selected>Select your industry...</option>
        <option *ngFor="let i of industries$ | async" [ngValue]="i">{{ i.name }}</option>
      </select>
    </ng-template>
  </ng-template>

  <ng-template #weightCell let-data="adtData">
    <ng-container *ngIf="!data.editing; else editCompanyRecordSectorWeightTemplate">
      {{ data.weight ?? (!data.id ? 'Set Weight...' : '') }}
    </ng-container>
    <ng-template #editCompanyRecordSectorWeightTemplate>
      <span matTooltip="Sector weights will be automatically calculated when the sector is saved">{{ data.weight }}</span>
    </ng-template>
  </ng-template>

  <ng-template #scopeCell let-data="adtData">
    <ng-container *ngIf="!data.editing; else editCompanyRecordSectorScopeTemplate">
      {{ SECTOR_SCOPE[data.scope] }}
    </ng-container>
    <ng-template #editCompanyRecordSectorScopeTemplate>
      <select class="form-control" [(ngModel)]="data.scope" required>
        <option *ngFor="let scope of SECTOR_SCOPES" [ngValue]="scope">{{ SECTOR_SCOPE[scope] }}</option>
      </select>
    </ng-template>
  </ng-template>

  <ng-template #actionCell let-data="adtData">
    <div *ngIf="dtOptions">
      <ng-container *ngIf="data.editing">
        <ng-container *ngIf="!data.id">
          <button class="btn btn-primary btn-sm mr-2" (click)="add(data, editingSectors)">Save</button>
          <button class="btn btn-link btn-sm" (click)="cancel(data, editingSectors)">Cancel</button>
        </ng-container>
        <ng-container *ngIf="data.id">
          <button class="btn btn-primary btn-sm mr-2" (click)="update(data, editingSectors)">Save</button>
          <button class="btn btn-danger btn-sm" (click)="delete(data, editingSectors)">Remove</button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!data.editing">
        <button class="btn btn-primary btn-sm mr-2" (click)="data.editing = true">Edit</button>
        <button *ngIf="data.status !== 'VERIFIED'" class="btn btn-success btn-sm" (click)="updateStatus(data, editingSectors, 'VERIFIED')">Verify</button>
        <button *ngIf="data.status === 'VERIFIED' && data.source === 'AUTOMATED'" class="btn btn-warning btn-sm" (click)="updateStatus(data, editingSectors, 'PENDING_REVIEW')">Unverify</button>
      </ng-container>
    </div>
  </ng-template>
</section>

<ng-template #loading>
  <div class="mt-4 text-center">Loading sectors...</div>
</ng-template>
