// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Pipe, PipeTransform } from '@angular/core';
import { Format } from 'src/app/dataset/dataset.model';
import { CurrencyConverterPipe } from './currency-converter.pipe';

@Pipe({ name: 'intensityDisplay' })
export class IntensityDisplayPipe implements PipeTransform {
  constructor(private currencyConverter: CurrencyConverterPipe) {}
  transform(value: number, format: keyof typeof Format = 'CURRENCY'): number {
    return value ? Number(this.currencyConverter.transform(value, format === 'CURRENCY' ? 'from' : 'none', false)) : 0;
  }
}
