import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface GeneralDialogData {
  title: string;
  subTitle: string;
  content: string;
  showSkip: boolean;
}

@Component({
  selector: 'app-general-dialog',
  templateUrl: 'general-dialog.component.html',
  styleUrls: ['general-dialog.component.scss'],
})
export class GeneralDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GeneralDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GeneralDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
