// @ts-strict-ignore
// Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { toSignal } from '@angular/core/rxjs-interop';
import { ReplaySubject } from 'rxjs';
import { EntityService } from '../core/entity/entity.service';
import { OnChange } from '../decorators/on-change';
import { AlertService } from '../shared/alert.service';
import { Currency } from './currency.model';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService extends EntityService<Currency> {
  readonly selectedCurrency$ = new ReplaySubject<Currency>();

  @OnChange((self, value) => self.selectedCurrency$.next(value))
  selectedCurrency: Currency;
  readonly getSelectedCurrency = toSignal(this.selectedCurrency$);

  readonly CURRENCY_COOKIE = 'currency';

  constructor(
    protected httpClient: HttpClient,
    protected alertService: AlertService,
  ) {
    super(alertService, httpClient);
  }

  initialise() {
    const existingCurrency = sessionStorage.getItem(this.CURRENCY_COOKIE);

    if (existingCurrency) {
      this.setSelectedCurrency(JSON.parse(existingCurrency));
    } else {
      // FIXME: Should get the default currency from the backend. In future, exchange rate may get updated. Also,
      // when going global, user in NZ will expect default currency is NZD not AUD.
      this.setSelectedCurrency({
        id: 1,
        name: 'Australian Dollar',
        short: 'AUD',
        exchangeRate: 0.72,
        symbol: '$',
      });
    }
  }

  setSelectedCurrency(currency: Currency) {
    this.selectedCurrency = currency;
    sessionStorage.setItem(this.CURRENCY_COOKIE, JSON.stringify(currency));
  }

  pluralIdentifier(): string {
    return 'currencies';
  }

  singularIdentifier(): string {
    return 'currency';
  }
}
