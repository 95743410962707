<ol>
  <li *ngFor="let group of groups" role="button" (click)="groupSelected.emit(group)">
    <ul>
      <li class="no-bullet-after flex-b-100">
        <span
          >Add {{ group.sectors?.length }} sector{{ group.sectors?.length > 1 ? 's' : ''
          }}<span *ngIf="group.name">
            from <b>"{{ group.name }}"</b></span
          >:
        </span>
      </li>
      <ng-container *ngFor="let sector of group.sectors | groupByCountry">
        <li>
          <b>{{ sector.country.name }}</b>
        </li>
        <li *ngFor="let industry of sector.industries" title="{{ industry.description }}">{{ industry.name }}</li>
      </ng-container>
    </ul>
  </li>
</ol>

<section *ngIf="!groups">Loading...</section>

<section *ngIf="groups?.length === 0">No results</section>
