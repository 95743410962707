import { Pipe, PipeTransform } from '@angular/core';
import { Country } from 'src/app/country/country.model';
import { Industry, Sector } from 'src/app/industry/industry.model';

@Pipe({ name: 'groupByCountry' })
export class GroupByCountryPipe implements PipeTransform {
  transform(sectors?: Sector[]): { country: Country; industries: Industry[] }[] {
    if (!sectors) {
      return [];
    }
    const groupedSectors = groupBy(sectors, s => s.country.short);
    return Object.values(groupedSectors).map(gs => ({ country: gs[0].country, industries: gs.map(s => s.industry) }));
  }
}

const groupBy = <T>(xs: T[], get: (x: T) => string) =>
  xs.reduce<{ [k: string]: T[] }>(
    (prev, current) => ({
      ...prev,
      [get(current)]: [...(prev[get(current)] ?? []), current],
    }),
    {},
  );
