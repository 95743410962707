// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Article } from '../../news-feed/articles/article.model';
import { Mode } from 'src/app/assessment/assessment.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
// TODO: Imo Rename to something like `NewsArticleComponent` or `NewsFeedArticleComponent`
export class ArticleComponent implements OnChanges {
  @Input() article: Article;
  @Input() showMode = true;

  modes: string[];
  MODE = Mode;

  liked = false;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.article.currentValue) {
      this.modes = changes.article.currentValue.mode.split(',');
      changes.article.currentValue.description = this.stripHtmlTags(changes.article.currentValue.description);

      // Use inner html to show html and images
      // changes.article.currentValue.description = this.sanitizer.sanitize(
      //   SecurityContext.SCRIPT || SecurityContext.HTML || SecurityContext.STYLE,
      //   changes.article.currentValue.description
      // );
    }
  }

  stripHtmlTags(text: string): string {
    const div = document.createElement('div');
    div.innerHTML = text;
    const strippedText = div.textContent || div.innerText || '';
    div.remove(); // Removes the div element from the DOM
    return strippedText;
  }
}
