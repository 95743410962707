// Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Assessment } from 'src/app/assessment/assessment.model';

@Pipe({
  name: 'legacyAssessmentCurrency',
})
export class LegacyAssessmentCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  /**
   * Transforms and returns the formatted value for the raw currency value.
   *
   * @param value The raw currency value to be formatted.
   * @param assessment The assessment that the value belongs to. Used to determine the chosen currency. If not provided, defaults to AUD.
   */
  transform(value: number, assessment?: Assessment): string | null {
    // value is null or undefined, treat as 0. web-api may return such values to signify 0.
    if (value == null) {
      return '0';
    }

    if (isNaN(value) || value === Infinity) {
      return null;
    }

    // If no dataset or no currency for dataset, assume AUD.
    const currencyCode = assessment?.currency?.short || 'AUD';

    // Specify format for all currencies, consistently don't show cents.
    const digitsInfo = '1.0-0';

    return this.currencyPipe.transform(value, currencyCode, 'symbol-narrow', digitsInfo);
  }
}
