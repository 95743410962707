// Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Client } from '../client/client.model';
import { HasId } from '../core/entity/entity.service';
import { getEnumKeyFromValue } from '../shared/util';
import { _enforceSameEnumKeys } from '../shared/util.tooling';
import { User } from '../user/user.model';

/**
 * Model used to represent a dataset upload, when user creates a dataset by uploading a file.
 */
export interface DatasetUpload extends HasId {
  name: string;
  client: HasId;
  primaryCountry: HasId;
  transactionType: keyof typeof TransactionType;
  format: keyof typeof Format;
  transactionDateStart: string;
  transactionDateEnd: string;
  file: File;
  currency: HasId;

  /**
   * Reference an existing dataset. If this field is set, the dataset will be updated.
   */
  dataset: HasId;
}

export interface Dataset extends HasId {
  name: string;

  client: Client;

  format: keyof typeof Format;

  /**
   * The user that created the dataset.
   *
   * NOTE: This field is read only. It is set by the server when this dataset is created.
   */
  createdBy: User;

  // FIXME: If the dataset is returned from the backend, this field is actually a string, not a `Date`.
  created: Date;
  // FIXME: If the dataset is returned from the backend, this field is actually a string, not a `Date`.
  modified: Date;
}

/**
 * Old/Original concept of dataset type. I believe we are moving towards using `TransactionType` instead.
 */
export enum EType {
  // NOTE: Order of enum declaration matters as UI elements eg. tabs rely on this. `SUPPLIER`, then `INVESTMENT`.
  SUPPLIER = 'SUPPLIER',
  INVESTMENT = 'INVESTMENT',
}
/** @deprecated use {@link EType} instead. */
export enum Type {
  SUPPLIER = 'Supplier',
  INVESTMENT = 'Investment',
}
_enforceSameEnumKeys(EType, Type);

export enum ETransactionType {
  EXPENDITURE = 'EXPENDITURE',
  INVESTMENT = 'INVESTMENT',
  REVENUE = 'REVENUE',
}

/** @deprecated use {@link ETransactionType} instead. */
export enum TransactionType {
  EXPENDITURE = 'Expenditure',
  INVESTMENT = 'Investment',
  REVENUE = 'Revenue',
}
_enforceSameEnumKeys(ETransactionType, TransactionType);

/**
 * Legacy: Support datasets that have amounts all in percentages.
 */
export enum Format {
  CURRENCY = 'Currency',
  PERCENTAGE = 'Percentage',
}

export const companyTypeToTransactionType = (type: keyof typeof Type) => {
  if (type === getEnumKeyFromValue(Type, Type.SUPPLIER)) {
    return getEnumKeyFromValue(TransactionType, TransactionType.EXPENDITURE);
  }

  if (type === getEnumKeyFromValue(Type, Type.INVESTMENT)) {
    return getEnumKeyFromValue(TransactionType, TransactionType.INVESTMENT);
  }

  return null;
};

export const transactionTypeToCompanyType = (transactionType: keyof typeof TransactionType) => {
  if (transactionType === getEnumKeyFromValue(TransactionType, TransactionType.EXPENDITURE)) {
    return getEnumKeyFromValue(Type, Type.SUPPLIER);
  }

  if (transactionType === getEnumKeyFromValue(TransactionType, TransactionType.INVESTMENT)) {
    return getEnumKeyFromValue(Type, Type.INVESTMENT);
  }

  return null;
};
