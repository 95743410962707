<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<div id="stupidWrapperElement3" class="block h-100 d-flex flex-column align-items-stretch p-4 gap-4">
  <header class="d-flex w-100 align-items-center">
    <h2 class="m-0">Latest News</h2>
    <section class="pagination ml-auto" data-toggle="buttons">
      <div class="stupidWrapper page-item" [class.disabled]="currentIndex === 0">
        <button class="page-link" (click)="currentIndex = currentIndex - 1" [disabled]="currentIndex === 0">Previous</button>
      </div>
      <div class="stupidWrapper page-item" [class.disabled]="!(articles$ | async)?.length || currentIndex === (articles$ | async)?.length - 1">
        <button class="page-link" (click)="currentIndex = currentIndex + 1">Next</button>
      </div>
    </section>
  </header>

  <ng-container *ngIf="articles$ | async as articles">
    <app-article *ngIf="articles[currentIndex]" [showMode]="false" [article]="articles[currentIndex]" />
    <p *ngIf="!articles[currentIndex]">No articles found</p>
  </ng-container>
  <p *ngIf="articles$ === undefined || (articles$ | async) === null">Loading...</p>
</div>
