// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, Input, OnChanges } from '@angular/core';
import { Mode } from 'src/app/assessment/assessment.model';
import learningData from './learning-data';
import { AssessmentDefinitions } from 'src/app/assessment/assessment.definitions';

@Component({
  selector: 'app-learning-widget',
  templateUrl: './learning-widget.component.html',
  styleUrls: ['./learning-widget.component.scss'],
})
export class LearningWidgetComponent implements OnChanges {
  @Input() mode: keyof typeof Mode;

  readonly DEFINITIONS = AssessmentDefinitions;

  protected currentIndex = 0;

  get data() {
    return learningData[this.mode];
  }

  ngOnChanges() {
    this.currentIndex = 0;
  }
}
