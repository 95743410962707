// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AuthService } from '../core/auth/auth.service';

/**
 * Add to any element that should only be visible for administrators
 */
@Directive({
  selector: '[appOnlyAdmin]',
})
export class OnlyAdminDirective implements OnInit, OnDestroy {
  authorised$: Subscription;

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authorised$ = this.authService
      .isAdmin$()
      .pipe(take(1))
      .subscribe((isAdmin: boolean) => {
        if (isAdmin) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy() {
    this.authorised$.unsubscribe();
  }
}
