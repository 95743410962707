// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { Observable, combineLatest, first, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { WeightedSector } from 'src/app/assessment/assessment-item.model';
import {
  SectorScope,
  SectorSource,
  SectorStatus,
} from 'src/app/company-record/company-record-sectors/company-record-sector.model';
import { EntityService, HasId } from 'src/app/core/entity/entity.service';
import { Country } from 'src/app/country/country.model';
import { Industry, Sector, SectorWithWeight } from 'src/app/industry/industry.model';
import { SectorService } from 'src/app/sector/sector.service';
import { SectorListService } from 'src/app/ui/sector-list/sector-list.service';

import { getEnumKeyFromValue } from '../util';

@Component({
  selector: 'app-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss'],
  styles: [':host { display: block }'],
})
export class SectorsComponent implements OnInit {
  @ViewChild('countryCell', { static: false })
  countryCell: TemplateRef<HTMLElement>;

  @ViewChild('industryCell', { static: false })
  industryCell: TemplateRef<HTMLElement>;

  @ViewChild('weightCell', { static: false })
  weightCell: TemplateRef<HTMLElement>;

  @ViewChild('scopeCell', { static: false })
  scopeCell: TemplateRef<HTMLElement>;

  @ViewChild('actionCell', { static: false })
  actionCell: TemplateRef<HTMLElement>;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  @Input() editable: boolean;
  @Input() sectors$: Observable<EditingSector[]>;
  @Input() entityDetails: { name: string; id: number; lookup: string };
  @Input() editingService: EntityService<EditingSector>;

  @Output() changed: EventEmitter<void> = new EventEmitter();

  protected countries$: Observable<Country[]>;
  protected industries$: Observable<Industry[]>;

  protected dtOptions: DataTables.Settings = null;

  readonly SECTOR_SCOPE = SectorScope;
  readonly SECTOR_SCOPES = Object.keys(SectorScope);

  constructor(
    private sectorListService: SectorListService,
    private sectorService: SectorService,
  ) {}

  ngOnInit() {
    this.countries$ = this.sectorListService.countries$;
    this.industries$ = this.sectorListService.industries$;

    if (!this.entityDetails) {
      return console.log('EntityDetails is missing');
    }

    this.sectors$ = this.sectors$.pipe(
      first(),
      tap((sectors: EditingSector[]) => {
        this.dtOptions = {
          data: sectors,
          columns: this.getColumns(),
        };
      }),
    );
  }

  protected blankSector(): Sector {
    return {
      country: { name: '', short: '' },
      industry: { name: '' },
    };
  }

  /**
   * Adds sectors to the editing set, and associates them with the parent entity.
   * @param sectors the sectors to import.
   * @param target the mutable array of sectors to add them to.
   */
  protected import(sectors: Sector[], target: EditingSector[], skipExisting = true) {
    const entityObject = { [this.entityDetails.name]: { id: this.entityDetails.id } };
    const newSectors = sectors
      .filter(candidate => !skipExisting || !target.some(existing => sectorsEqual(candidate, existing.sector)))
      // Add missing fields, and explicitly drop the id to ensure a new one is created.
      .map(({ id, ...sector }) => ({
        sector,
        weight: null,
        scope: getEnumKeyFromValue(SectorScope, SectorScope.EXPENDITURE),
        source: getEnumKeyFromValue(SectorSource, SectorSource.MANUAL),
        status: getEnumKeyFromValue(SectorStatus, SectorStatus.VERIFIED),
        editing: true,
      }))
      .map(sector => ({ ...sector, ...entityObject }));
    target.push(...newSectors);

    this.redrawTable(target);
  }

  /**
   * Persists the new sector.
   * @param sector
   */
  protected add(sector: EditingSector, sectors: EditingSector[]) {
    this.editingService
      .addOne$(sector)
      .pipe(
        first(),
        tap((addedSector: EditingSector) => {
          sector.id = addedSector.id;
          sector.sector.id = addedSector.sector.id;
          sector.editing = false;
        }),
        switchMap(() => this.updateSectorWeights$(sectors)),
      )
      .subscribe(updatedSectors => this.onSectorsChanged(updatedSectors));
  }

  /**
   * Updates the existing sector.
   * @param sector
   * @param sectors
   */
  protected update(sector: EditingSector, sectors: EditingSector[]) {
    // The edited sector to update is an object inside the sectors array.
    this.updateSector$(sector)
      .pipe(switchMap(() => this.updateSectorWeights$(sectors)))
      .subscribe(updatedSectors => this.onSectorsChanged(updatedSectors));
  }

  /**
   * Removes the existing sector.
   * @param sector
   * @param sectors
   */
  protected delete(sector: EditingSector, sectors: EditingSector[]) {
    this.editingService
      .deleteOne$(sector.id)
      .pipe(
        first(),
        tap(() => sectors.splice(sectors.indexOf(sector), 1)),
        switchMap(() => (sectors.length ? this.updateSectorWeights$(sectors) : of([]))),
      )
      .subscribe((updatedSectors: EditingSector[]) => this.onSectorsChanged(updatedSectors));
  }

  /**
   * Remove the unsaved sector.
   * @param sector
   * @param sectors
   */
  protected cancel(sector: EditingSector, sectors: EditingSector[]) {
    sectors.splice(sectors.indexOf(sector), 1);
    this.onSectorsChanged(sectors, false);
  }

  protected updateStatus(sector: EditingSector, sectors: EditingSector[], status: keyof typeof SectorStatus) {
    this.editingService
      .updateOne$(sector.id, { status })
      .pipe(first())
      .subscribe((updatedSector: EditingSector) => {
        // Set the sector in the array to be marked as the new status for the upcoming redraw
        sectors.filter(s => s.id === sector.id).map(s => (s.status = status));

        this.onSectorsChanged(sectors);
      });
  }

  /**
   * Determines the weight of each sector and updates them all.
   * @param sectors
   */
  protected calculateWeights(sectors: EditingSector[]) {
    this.updateSectorWeights$(sectors).subscribe(updatedSectors => this.onSectorsChanged(updatedSectors));
  }

  protected compareIds(a: HasId, b: HasId): boolean {
    return a?.id === b?.id;
  }

  private getColumns(): ADTColumns[] {
    let columns = [
      {
        // Scope
        data: (row: EditingSector) => row.scope,
        className: 'align-middle',
        ngTemplateRef: {
          ref: this.scopeCell,
        },
      },
      {
        // Country
        data: (row: EditingSector) => row.sector?.country?.name || '',
        className: 'align-middle',
        ngTemplateRef: {
          ref: this.countryCell,
        },
      },
      {
        // Industry
        data: (row: EditingSector) => row.sector?.industry?.name || '',
        className: 'align-middle',
        ngTemplateRef: {
          ref: this.industryCell,
        },
      },
      {
        // Weight
        data: (row: EditingSector) => row.weight || 0,
        className: 'align-middle text-right',
        ngTemplateRef: {
          ref: this.weightCell,
        },
      },
      {
        // Source
        data: (row: EditingSector) => SectorSource[row.source],
        className: 'align-middle',
      },
      {
        // Status
        data: (row: EditingSector) => SectorStatus[row.status],
        className: 'align-middle',
      },
    ];

    if (this.editable) {
      columns = columns.concat([
        {
          // Action
          data: (row: EditingSector) => row.id || 0,
          className: 'align-middle',
          ngTemplateRef: {
            ref: this.actionCell,
          },
        },
      ]);
    }

    return columns;
  }

  private redrawTable(sectors: EditingSector[]) {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.rows.add(sectors);
        this.dtOptions.data = [...sectors];
        dtInstance.draw(false);
      });
    }
  }

  private updateSector$(sector: EditingSector): Observable<EditingSector> {
    return this.editingService.updateOne$(sector.id, sector).pipe(
      first(),
      tap((updatedSector: EditingSector) => {
        sector.sector.id = updatedSector.sector.id;
        sector.editing = false;
      }),
    );
  }

  /**
   * Calculate sector weights and updates each of the sector's weight.
   */
  private updateSectorWeights$(sectors: EditingSector[]): Observable<EditingSector[]> {
    const requests$: Observable<EditingSector[]>[] = [];

    // Calculate separately per scope, each respective sectors weight.
    for (const scope of [SectorScope.EXPENDITURE, SectorScope.INVESTMENT, SectorScope.REVENUE]) {
      requests$.push(this.updateSectorWeightsByScope$(sectors, getEnumKeyFromValue(SectorScope, scope)));
    }

    // Concat each of the sectors back into the single array once completed.
    return combineLatest(requests$).pipe(map(a => a.reduce((acc, curr) => acc.concat(curr), [])));
  }

  private updateSectorWeightsByScope$(
    sectors: EditingSector[],
    scope: keyof typeof SectorScope,
  ): Observable<EditingSector[]> {
    // Ignore any sectors that are not yet saved to the backend and not of the specified scope.
    const activeSectors = sectors.filter(s => s.id && s.scope === scope);

    if (activeSectors.length === 0) {
      return of(activeSectors);
    }

    return this.sectorService.calculateWeights$(activeSectors.map(s => s.sector.id)).pipe(
      first(),
      map((sectorsWithWeight: SectorWithWeight[]) => {
        activeSectors.forEach((sector: EditingSector) => {
          sector.weight = sectorsWithWeight.find(s => s.id === sector.sector.id).weight;
        });
        return activeSectors;
      }),
      switchMap((sectorsToUpdate: EditingSector[]) =>
        // We got the weights for each of the sectors. Now, save these weights for the sectors.
        forkJoin(sectorsToUpdate.map(s => this.updateSector$(s))).pipe(
          // NOTE: We can just return the initial function param because ATM, every sector is updated in-place and passed by reference.
          map(() => sectorsToUpdate),
        ),
      ),
    );
  }

  /**
   * Common handler for when the sectors (add/update/delete) are changed and saved.
   * @param sectors Changed and saved sectors.
   * @param emitEvent Whether to emit the changed event to the parent component.
   */
  private onSectorsChanged(sectors: EditingSector[], emitEvent = true) {
    // redrawTable to recalculate the total weights in table footer.
    this.redrawTable(sectors);
    if (emitEvent) {
      this.changed.next();
    }
  }
}

interface EditingSector extends WeightedSector, HasId {
  editing: boolean;
}

const sectorsEqual = (a: Sector, b: Sector) =>
  a?.country?.short === b?.country?.short && a?.industry?.id === b?.industry?.id;
