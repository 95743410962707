// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sector } from 'src/app/industry/industry.model';

type Group = { name: string; sectors: Sector[] };

@Component({
  selector: 'app-sector-groups',
  templateUrl: './sector-group.component.html',
  styleUrls: ['./sector-group.component.scss'],
})
export class SectorGroupsComponent {
  @Input()
  groups: Group[] = [];

  @Input()
  existing: { sector: Sector }[];

  @Output() groupSelected = new EventEmitter<Group>();
}
