<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<div id="stupidWrapperElement4" class="block h-100 d-flex flex-column align-items-center p-4 gap-4">
  <header class="d-flex w-100 align-items-center">
    <h2>Learning</h2>
    <section class="pagination ml-auto" data-toggle="buttons">
      <div class="stupidWrapper page-item" [class.disabled]="currentIndex === 0">
        <button class="page-link" (click)="currentIndex = currentIndex - 1">Previous</button>
      </div>
      <div class="stupidWrapper page-item" [class.disabled]="!data || currentIndex === data.length - 1">
        <button class="page-link" (click)="currentIndex = currentIndex + 1">Next</button>
      </div>
    </section>
  </header>

  <article *ngIf="data?.[currentIndex]">
    <h3>{{ data[currentIndex].header }}</h3>
    <p>{{ data[currentIndex].content }}</p>
    <a class="btn btn-outline mt-4" href="{{ data[currentIndex].link }}" target="_blank">Learn More</a>
  </article>
  <p *ngIf="!data?.[currentIndex]">No information yet.</p>
</div>
