<button mat-icon-button style="align-self: end" mat-dialog-close tabindex="-1">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.subTitle }}</p>
  <p>{{ data.content }}</p>
</div>
<div mat-dialog-actions [class.even-buttons]="!data.showSkip">
  <button mat-flat-button color="primary" (click)="onNoClick()">Label</button>
  <button mat-stroked-button>Label</button>
  <button *ngIf="data.showSkip" style="margin-left: auto" mat-button mat-dialog-close>Skip</button>
</div>
