<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<div>
  <div class="d-flex align-items-centre justify-content-centre">
    <h5 class="mr-2">
      {{ article.source }}<span class="text-muted mx-2" style="font-size: 40px">.</span><span class="text-muted">{{ article.pubDate ? (article.pubDate | date) : 'No date provided' }}</span>
    </h5>
  </div>
  <ng-container *ngIf="showMode">
    <div class="btn no-shadow mr-1 mb-2 pb-2 mode-tag no-pointer" [ngClass]="m.toLowerCase()" *ngFor="let m of modes">
      <span>{{ MODE[m] }}</span>
    </div>
  </ng-container>

  <h3 class="my-3">{{ article.title }}</h3>
  <div style="font-size: 16px" class="mb-3">{{ article.description }}</div>

  <div class="mb-3 d-flex gap-3 flex-wrap" aria-label="Tags">
    <mat-chip class="" *ngFor="let c of article.categories">{{ c }}</mat-chip>
  </div>
  <div class="link">
    <div class="d-flex align-items-center" style="height: 75px">
      <div class="d-flex justify-content-between w-100">
        <a href="{{ article.link }}" target="_blank">
          <h5>View Full Article</h5>
        </a>
      </div>
    </div>
  </div>
</div>
