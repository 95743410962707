// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, Input, OnChanges } from '@angular/core';
import { Observable, shareReplay, startWith } from 'rxjs';
import { Mode } from 'src/app/assessment/assessment.model';
import { Article } from 'src/app/news-feed/articles/article.model';
import { ArticlesService } from 'src/app/news-feed/articles/articles.service';

@Component({
  selector: 'app-news-feed-widget',
  templateUrl: './news-feed-widget.component.html',
})
export class NewsFeedWidgetComponent implements OnChanges {
  @Input() mode: keyof typeof Mode;

  protected articles$: Observable<Article[]>;

  protected currentIndex = 0;

  constructor(private articleService: ArticlesService) {}
  ngOnChanges() {
    this.currentIndex = 0;
    this.articles$ = this.articleService.getFeed$({ mode: this.mode }).pipe(startWith(null), shareReplay(1));
  }
}
