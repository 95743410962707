// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Article } from './article.model';
import { environment } from 'src/environments/environment';

export enum DateRange {
  HOUR = 'Past hour',
  DAY = 'Past 24 hours',
  MONTH = 'Past month',
  YEAR = 'Past year',
}

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  constructor(protected httpClient: HttpClient) {}

  getFeed$(
    params?: HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[] },
  ): Observable<Article[]> {
    let url = environment.server.rssURL;
    // Ensure trailing slash for our django api calls. Or else, there will be unnecessary 301 redirects.
    url = url.endsWith('/') ? url : url + '/';

    return this.httpClient
      .get(url, {
        params,
        responseType: 'text',
      })
      .pipe(
        map(response => {
          const feed = this.xmlToJson(response);
          return feed.items;
        }),
      );
  }

  //Conversion of XML to JSON object
  xmlToJson(xmlString: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

    const result: { title?: string; link?: string; description?: string; items?: Article[] } = {};
    const channelNode = xmlDoc.querySelector('channel');
    const itemNodes = channelNode.querySelectorAll('item');

    result.title = channelNode.querySelector('title').textContent;
    result.link = channelNode.querySelector('link').textContent;
    result.description = channelNode.querySelector('description').textContent;
    result.items = [];

    itemNodes.forEach(itemNode => {
      const item: Article = {
        title: itemNode.querySelector('title').textContent,
        source: itemNode.querySelector('source').textContent,
        pubDate: itemNode.querySelector('pubDate')?.textContent,
        mode: itemNode.querySelector('mode').textContent,
        link: itemNode.querySelector('link').textContent,
        description: itemNode.querySelector('description').textContent,
        categories: [],
      };

      const categoryNodes = itemNode.querySelectorAll('category');
      categoryNodes.forEach(categoryNode => {
        item.categories.push(categoryNode.textContent);
      });

      result.items.push(item);
    });

    return result;
  }
}
