import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiModule } from 'src/app/ui/ui.module';
import { GeneralDialogComponent } from './general-dialog/general-dialog.component';

const dialogs = [GeneralDialogComponent];

@NgModule({
  imports: [CommonModule, FormsModule, UiModule],
  declarations: dialogs,
  exports: dialogs,
})
export class DialogsModule {}
