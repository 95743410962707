// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgLetModule } from 'ng-let';

import { LoadingModule } from '../fs-next/loading/loading.module';
import { UiModule } from '../ui/ui.module';
import { AlertComponent } from './alert/alert.component';
import { ArticleComponent } from './article/article.component';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';
import { DialogsModule } from './dialogs/dialogs.module';
import { LearningWidgetComponent } from './learning-widget/learning-widget.component';
import { NewsFeedWidgetComponent } from './news-feed-widget/news-feed-widget.component';
import { OnlyAdminDirective } from './only-admin.directive';
import { AbsoluteValuePipe } from './pipes/absolute-value.pipe';
import { LegacyAssessmentCurrencyPipe } from './pipes/assessment-currency.pipe';
import { CurrencyConverterPipe } from './pipes/currency-converter.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { IntensityDisplayPipe } from './pipes/intensity-display.pipe';
import { PrecisionPipe } from './pipes/precision-pipe';
import { ProperCasePipe } from './pipes/proper-case.pipe';
import { SignificantFiguresPipe } from './pipes/significant-figures.pipe';
import { GroupByCountryPipe } from './sectors/sector-group/group-by-country.pipe';
import { SectorGroupsComponent } from './sectors/sector-group/sector-group.component';
import { SectorsComponent } from './sectors/sectors.component';

@NgModule({
  declarations: [
    OnlyAdminDirective,
    CopyToClipboardDirective,
    AlertComponent,
    ProperCasePipe,
    PrecisionPipe,
    CurrencyConverterPipe,
    SignificantFiguresPipe,
    LegacyAssessmentCurrencyPipe,
    AbsoluteValuePipe,
    IntensityDisplayPipe,
    NewsFeedWidgetComponent,
    ArticleComponent,
    LearningWidgetComponent,
    GroupByCountryPipe,
    SectorGroupsComponent,
    SectorsComponent,
  ],
  imports: [CommonModule, DataTablesModule, FormsModule, NgLetModule, UiModule, LoadingModule, DateAgoPipe],
  exports: [
    OnlyAdminDirective,
    CopyToClipboardDirective,
    AlertComponent,
    ProperCasePipe,
    PrecisionPipe,
    CurrencyConverterPipe,
    SignificantFiguresPipe,
    LegacyAssessmentCurrencyPipe,
    AbsoluteValuePipe,
    IntensityDisplayPipe,
    NewsFeedWidgetComponent,
    ArticleComponent,
    LearningWidgetComponent,
    SectorsComponent,
    DialogsModule,
    LoadingModule,
    DateAgoPipe,
  ],
})
export class SharedModule {}
