// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Mode } from 'src/app/assessment/assessment.model';
import { getEnumKeyFromValue } from 'src/app/shared/util';

const learningData = {
  [getEnumKeyFromValue(Mode, Mode.SLAVES)]: [
    {
      header: 'What is Modern Slavery?',
      content: `Modern Slavery is a severe form of exploitation of individuals for the purpose of personal or commercial gain
          and is prohibited under Article 4 of the UN Declaration of Human Rights.\n\n
          The Modern Slavery Act 2018 (Cth) identifies eight serious forms of modern slavery: trafficking in persons;
          slavery, servitude; forced marriage; forced labour; debt bondage; the worst forms of child labour; and
          deceptive recruiting for labour or services.\n\n
          The ILO estimates that there are over 40 million people currently enslaved around the world. This slavery is
          often hidden deep in supply chains.`,
      link: 'https://www.fairsupply.com/learn/modern-slavery',
    },
    {
      header: 'What does the Modern Slavery Act 2018 (Cth) mean for your business?',
      content: `Australia's Modern Slavery Act 2018 (Cth) requires entities with an annual consolidated revenue over $100
          million based or operating in Australia to report on the risks of modern slavery in their operations and
          supply chains and the actions taken to address those risks.\n\n
          Each reporting entity must submit an annual Modern Slavery Statement that meets the seven mandatory reporting
          criterion to the Australian Border Force Modern Slavery Register.`,
      link: 'https://www.fairsupply.com/learn/modern-slavery',
    },
    {
      header: 'How to address modern slavery in your supply chain?',
      content: `To address the often hidden crime of modern slavery effectively requires deep visibility over your supply
          chain. Your supplier. Your supplier's supplier. That supplier's supplier. And so forth. Tier upon Tier.\n\n
          FairSupply research into Australia Modern Slavery Statements reveals only 6% of reporting entities in
          Australia look beyond Tier 1 of their supply chain for the risk of modern slavery.\n\n
          Manual approaches won't provide deep multi-tier visibility, rather FairSupply's super computer assisted
          predictive analytics are needed.`,
      link: 'https://www.fairsupply.com/learn/modern-slavery',
    },
  ],
  [getEnumKeyFromValue(Mode, Mode.GHG_EMISSIONS)]: [
    {
      header: 'What is Greenhouse Gas Emissions?',
      content: `With the surge in ESG activity and investment, investors are seeking assurance that company value chains are
          not linked to carbon intensive technologies.\n\n
          A growing number of countries are requiring companies to report on greenhouse gas (GHG) emissions, both in
          their operations and value chain. The most widely used framework for climate-related financial reporting comes
          from the Task Force on Climate-related Financial Disclosures (TCFD), a body of the Financial Stability Board.\n\n
          The TCFD aims to improve transparency and reliability of climate-related financial information, increase
          reporting of consistent and comparable information, and enable stakeholders to make informed decisions based
          on climate-related risks. The TCFD framework focuses on four main areas: governance, strategy, risk management
          and metrics and targets. Recommended disclosures include Scope 1, Scope 2, and if appropriate, Scope 3 GHG
          emissions. Measurement of Scope 3 (value chain) emissions can be challenging for companies who have limited
          visibility of complex, global supply chains.`,
      link: 'https://www.fairsupply.com/learn/emissions',
    },
    {
      header: 'What is Scope 3?',
      content: `Emissions in the value chain of the entity are referred to as Scope 3 emissions.\n\n
          Because Scope 3 data lies in the supply chain rather than in the operations of an entity, measuring it becomes
          more difficult. Scope 3 data are very hard to assess, because both upstream and downstream emissions occur in
          millions - if not billions - of supply chains. As a result, reported Scope 3 emissions often rely on certain
          assumptions and subjective biases - but unlike Scopes 1 and 2, they are so far not required to follow
          rigorous, unified reporting standards. This lack of transparency regarding the reporting approaches renders
          most Scope 3 data with a high degree of uncertainty.\n\n
          FairSupply uses global trade flow data to analyse the supply chain (up to tier 10) of the following security
          types: listed equities, private equities, infrastructure assets and sovereign bonds. We provide visibility up
          to tier 10 of the supply chain, to identify GHG emissions within the supply chain of an entity's products,
          services and investments.`,
      link: 'https://www.fairsupply.com/learn/emissions',
    },
  ],
  [getEnumKeyFromValue(Mode, Mode.BIODIVERSITY)]: [
    {
      header: 'What is Biodiversity?',
      content: `Biodiversity is essential to life on earth, providing ecosystem services such as pollination, water and air
          purification, and natural resources which often form the basis of many of the finished products we consume. A
          recent study by the World Economic Forum and PWC
          found that more than 50% of global GDP is “moderately or highly dependent on nature and its services” and
          identified biodiversity loss as a significant risk to the global economy.\n\n
          Biodiversity is defined by the Convention on Biological Diversity
          as "the variability among living organisms from all sources including, inter alia, terrestrial, marine and
          other aquatic ecosystems and the ecological complexes of which they are part; this includes diversity within
          species, between species and of ecosystems.” With such a wide-ranging definition it can be daunting to
          identify a measure of biodiversity, let alone start to quantify its loss. At Fair Supply we focus on species
          extinction risk as the underlying proxy for biodiversity loss and use the scientific data curated in
          International Union for Conservation of Nature's (IUCN) Red List of Threatened Species.`,
      link: 'https://www.fairsupply.com/learn/biodiversity',
    },
    {
      header: 'What is an extinction-risk footprint?',
      content: `The extinction-risk footprint for each species is a quantitative representation of that species' proximity to
          extinction. The footprint value for each species is derived using the data curated in the
          IUCN Red List including its extinction risk
          category (Critically Endangered, Endangered, Vulnerable, or Near Threatened), and the scope and severity of
          threats acting on it. These footprint values are connected to countries using species distribution ranges and
          to economic sectors using the threat information. The extinction-risk footprint has no units, and the higher
          the footprint value for an entity, the higher its indirect impact on the extinction risk of species.`,
      link: 'https://www.fairsupply.com/learn/biodiversity',
    },
    {
      header: 'How do I determine whether my supply chain is tied to biodiversity loss?',
      content: `For most organisations, the biodiversity loss generated by their activity occurs deep within supply chains
          rather than at the point of operation. FairSupply partners the species data available in IUCN's Red List of
          Threatened Species with global trade flow data to analyse the supply chain (up to tier 10) of an entity's
          products, services, and investments, providing organisations with an understanding of how their supply chains
          indirectly impact the extinction risk of species.`,
      link: 'https://www.fairsupply.com/learn/biodiversity',
    },
  ],
  [getEnumKeyFromValue(Mode, Mode.LAND)]: [
    {
      header: 'What is Land Use?',
      content: `UN Convention to Combat Desertification argues our future economic
          growth, prosperity and well-being depend on protecting and restoring working landscapes. Moreover, land use is
          linked to both climate change mitigation and adaption.\n\n
          Investors are responding. At COP26, over 30 financial institutions with more than (US)$8.7 trillion in assets under management committed
          to work on eliminating agricultural commodity-driven deforestation risks in their investment and lending
          portfolios by 2025.\n\n
          Supply chain visibility is integral to mitigating land use risks. FairSupply provides visibility up to tier 10
          of the supply chain, to identify land use within the supply chain.`,
      link: 'https://www.unccd.int/issues/land-and-climate-change',
    },
  ],
  [getEnumKeyFromValue(Mode, Mode.WATER)]: [
    {
      header: 'What is Water Use?',
      content: `Issues of water scarcity present entities with physical, financial, regulatory and repetitional risks.\n\n
          UN Water states
          that water efficiency gains will play a central role in adapting to climate change. Sustainable Development
          Goal (SDG) target 6.4.1 is to, "by 2030, substantially increase water-use efficiency across all sectors and
          ensure sustainable withdrawals and supply of freshwater to address water scarcity and substantially reduce the
          number of people suffering from water scarcity."`,
      link: 'https://www.unwater.org/publications/progress-on-water-use-efficiency-641-2021-update/',
    },
    {
      header: 'How heavily water dependent is your organisation?',
      content: `Understanding supply chain dependencies are integral to mitigating water scarcity risk. FairSupply provides
          visibility up to tier 10 of the supply chain, to identify water use within the supply chain.`,
      link: 'https://www.unwater.org/publications/progress-on-water-use-efficiency-641-2021-update/',
    },
  ],
  [getEnumKeyFromValue(Mode, Mode.TIVA)]: [
    {
      header: 'What is Trade in Value Added (TiVA)?',
      content: `The price that goods and services are sold for is the result of a long line of intermediate steps. Each of
          these steps adds a certain amount of value to the product. For example, in order to produce cutlery, a company
          has to buy a number of raw materials, energy, and other inputs. A simplified example would be that a cutlery
          manufacturer need to acquire raw steel, but also energy to work with the steel, as well as a number of
          services such as transport in order to be able to ship the steel to their factory. All of these expenses must
          be recovered by the sale of the final product. Economic entities intend to recover more money than what they
          need to cover their outgoings. The phrase “a product is worth more than the sum of its parts” is a common
          description for this. The difference between the money received from sales and the money spent on inputs is
          the value that this specific manufacturing step has added.\n\n
          Each supply-chain node adds value, and the price of the final product as it is consumed by the final consumer
          is the sum of all value-add events along the supply chain. Deciphering where and how much value was added
          along the supply chain is commonly referred to as Trade in Value Added (TiVA).`,
      link: 'https://www.oecd.org/sti/ind/measuring-trade-in-value-added.htm',
    },
    {
      header: 'What insights does TiVA offer?',
      content: `TiVA offers companies, investors, and also final consumers an overview which industry, and where in the world
          these industries contributed significantly to the value of the final product. For example, during the Covid
          pandemic, supply-chain challenges came to the public attention for the first time, but often only specific
          sectors were singled out as examples where a lockdown would disrupt economic activity and subsequently cause
          supply-chain disruptions.\n\n
          Using a TiVA assessment, one can identify firstly which sectors in which countries are required to contribute
          to the final product, and secondly how big this contribution is. This knowledge empowers companies and
          investors to identify potential risk factors within supply chains, for example if a significant portion of the
          value is generated in a politically unstable country. Alternatively, events that affect the economic
          performance of sectors or entire countries can be checked against the TiVA assessment to forecast the impacts
          of these events on a specific company.\n\n
          Lastly, TiVA is an excellent tool for scenario analysis. Companies are for example able to assess how much the
          price of a certain required product would increase if a carbon tax is introduced in a specific country.`,
      link: 'https://www.oecd.org/sti/ind/measuring-trade-in-value-added.htm',
    },
  ],
};

export default learningData;
