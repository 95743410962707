import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'significantFigures',
})
export class SignificantFiguresPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined || isNaN(value)) {
      return value?.toString();
    }

    const numValue = Number(value);

    let formattedNumber = numValue.toString();

    if (numValue === 0) {
      formattedNumber = numValue.toFixed(0).toString();
    } else if (numValue < 0.0001) {
      formattedNumber = numValue.toFixed(5).toString();
    } else if (numValue < 0.001 && numValue >= 0.0001) {
      formattedNumber = numValue.toFixed(4).toString();
    } else if (numValue < 1 && numValue >= 0.001) {
      formattedNumber = numValue.toFixed(3).toString();
    } else if (numValue < 10 && numValue >= 1) {
      formattedNumber = numValue.toFixed(2).toString();
    } else if (numValue < 100 && numValue >= 10) {
      formattedNumber = numValue.toFixed(1).toString();
    } else {
      formattedNumber = numValue.toFixed(0).toString();
    }

    // Convert exponential notation to decimal, if necessary
    if (formattedNumber.includes('e')) {
      formattedNumber = this.convertExponentialToDecimal(formattedNumber);
    }

    // Format with commas as thousand separators
    return this.formatWithCommas(formattedNumber);
  }

  private convertExponentialToDecimal(exponentialNumber: string): string {
    const [lead, decimal, pow] = exponentialNumber.split(/e|\./);
    let result = lead + (decimal ? decimal : '');
    const power = parseInt(pow, 10);

    if (power < 0) {
      result = '0.' + '0'.repeat(Math.abs(power) - 1) + result;
    } else {
      const lengthAfterDecimal = decimal ? decimal.length : 0;
      if (lengthAfterDecimal <= power) {
        result += '0'.repeat(power - lengthAfterDecimal);
      } else {
        result = result.slice(0, power + 1) + '.' + result.slice(power + 1);
      }
    }
    return result;
  }

  private formatWithCommas(numberString: string): string {
    const parts = numberString.split('.');
    const numberFormat = new Intl.NumberFormat('en-US'); // Adjust the locale as needed
    const integerFormatted = numberFormat.format(parseInt(parts[0], 10));
    return parts.length > 1 ? `${integerFormatted}.${parts[1]}` : integerFormatted;
  }
}
