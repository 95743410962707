// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from './alert.service';

@Directive({
  selector: '[appCopyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input('appCopyToClipboard')
  payload: string | (() => string);

  @Output()
  copied = new EventEmitter<string>();

  constructor(
    private clipboard: Clipboard,
    private alertService: AlertService,
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.payload) {
      console.log('Payload not defined');
      return;
    }

    const stringPayload = typeof this.payload === 'string' ? this.payload : this.payload();
    const success = this.clipboard.copy(stringPayload);

    if (success) {
      this.alertService.showSuccess('Copied to clipboard');
      this.copied.emit(stringPayload);
    } else {
      this.alertService.showError('Failed to copy');
      this.copied.error('Failed to copy');
    }
  }
}
