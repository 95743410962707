// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, OnInit } from '@angular/core';

import { AlertService } from '../alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  type: string;

  visible: boolean;

  message: string = null;

  messages: { type: string; text: string; timeout: number }[] = [];

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alertService.showStandard$.subscribe(message => this.show('primary', message));
    this.alertService.showSuccess$.subscribe(message => this.show('success', message));
    this.alertService.showError$.subscribe(message => this.show('danger', message, 6000));
    this.alertService.clearAll$.subscribe(() => this.clearMessages());
  }

  show(type: string, text: string, timeout = 3000) {
    this.messages.push({
      type,
      text,
      timeout,
    });

    // Only run the show message check if not already running
    if (!this.visible && this.message === null) {
      this.showNextMessage();
    }
  }

  showNextMessage() {
    if (this.messages.length > 0) {
      this.visible = false;

      // Add a slight pause so that there is a distinction between successive alerts
      window.setTimeout(() => {
        const message = this.messages.shift();

        let timeout = 3000;

        // Check if the message has been cleared
        if (message) {
          this.type = message.type;
          this.message = message.text;
          this.visible = true;

          timeout = message.timeout;
        }

        window.setTimeout(() => this.showNextMessage(), timeout);
      }, 250);
    } else {
      this.message = null;
      this.visible = false;
    }
  }

  clearMessages() {
    this.messages = [];
    this.message = null;
    this.type = null;
    this.visible = false;
  }
}
